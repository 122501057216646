import { onMounted, onUnmounted } from 'vue'

export default (backButtonCallBack) => {
  onMounted(() => {
    window.history.pushState(null, '', location.href)
    window.onpopstate = () => {
      if (backButtonCallBack) {
        backButtonCallBack()
      }
    }
  })

  onUnmounted(() => {
    console.log('back button unmounted', location.href)
    window.history.replaceState(window.history.state, '', location.href)
    window.onpopstate = () => {}
  })
}
